


























































































import { Component, Vue } from 'vue-property-decorator';
import { dispatchGetSeTasks, dispatchUpdateSeTask } from '@/store/se-tasks/actions';
import { readOneSeTask } from '@/store/se-tasks/getters';
import { ISeTaskUpdate } from '@/interfaces/se-tasks';

@Component
export default class EditTask extends Vue {
  public valid = false;
  public name: string = '';
  public description: string = '';

  public async mounted() {
    await dispatchGetSeTasks(this.$store);
    this.reset();
  }

  public reset() {
    this.name = '';
    this.description = '';
    this.$validator.reset();
    if (this.task) {
      this.name = this.task.name;
      this.description = this.task.description;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedTask: ISeTaskUpdate = {
        name: this.name,
        description: this.description,
      };
      await dispatchUpdateSeTask(this.$store, { id: this.task!.id, task: updatedTask });
      this.$router.push('/main/se-tasks');
    }
  }

  get task() {
    return readOneSeTask(this.$store)(+this.$router.currentRoute.params.id);
  }

}
